import { Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// home pages
const HomeFashion = lazy(() => import("./pages/home/HomeFashion"));

const HomeElectronics = lazy(() => import("./pages/home/HomeElectronics"));
const HomeElectronicsThree = lazy(() => import("./pages/home/HomeElectronicsThree"));
const HomeBookStoreTwo = lazy(() => import("./pages/home/HomeBookStoreTwo"));

// shop pages
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));
const ShopGridFilter = lazy(() => import("./pages/shop/ShopGridFilter"));
const ShopGridTwoColumn = lazy(() => import("./pages/shop/ShopGridTwoColumn"));
const ShopGridNoSidebar = lazy(() => import("./pages/shop/ShopGridNoSidebar"));
const ShopGridFullWidth = lazy(() => import("./pages/shop/ShopGridFullWidth"));
const ShopGridRightSidebar = lazy(() =>
  import("./pages/shop/ShopGridRightSidebar")
);
const ShopListStandard = lazy(() => import("./pages/shop/ShopListStandard"));
const ShopListFullWidth = lazy(() => import("./pages/shop/ShopListFullWidth"));
const ShopListTwoColumn = lazy(() => import("./pages/shop/ShopListTwoColumn"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));
const ProductTabLeft = lazy(() =>
  import("./pages/shop-product/ProductTabLeft")
);
const ProductTabRight = lazy(() =>
  import("./pages/shop-product/ProductTabRight")
);
const ProductSticky = lazy(() => import("./pages/shop-product/ProductSticky"));
const ProductSlider = lazy(() => import("./pages/shop-product/ProductSlider"));
const ProductFixedImage = lazy(() =>
  import("./pages/shop-product/ProductFixedImage")
);

// blog pages
const BlogStandard = lazy(() => import("./pages/blog/BlogStandard"));
const BlogNoSidebar = lazy(() => import("./pages/blog/BlogNoSidebar"));
const BlogRightSidebar = lazy(() => import("./pages/blog/BlogRightSidebar"));
const BlogDetailsStandard = lazy(() =>
  import("./pages/blog/BlogDetailsStandard")
);

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));

const NotFound = lazy(() => import("./pages/other/NotFound"));


const CreooRedirect = () => {
  // Get brand from path segments
  const pathSegments = window.location.pathname.split('/').filter(segment => segment.length > 0);
  const brand = pathSegments[pathSegments.length - 1];
  console.log('brand')
  console.log(pathSegments)
  // Get domain from current URL
  const hostname = window.location.hostname;
  const domain = hostname.split('.').slice(-2).join('.');  // Gets "oem-creoo.com" from any subdomain
  
  const expiryDate = new Date();
  expiryDate.setFullYear(expiryDate.getFullYear() + 10);
  // Set new cookie with dynamic domain
  document.cookie = `brand=${brand};expires=${expiryDate.toUTCString()};path=/;domain=shop.${domain};SameSite=Lax`;
  console.log('setting cookie')
  console.log(brand)

  // Redirect to home
  setTimeout(function() {
  window.location.href = '/home';

  }, 100);
};
const CreooRedirect1 = () => {
  // Get brand and category from path segments
  const pathSegments = window.location.pathname.split('/').filter(segment => segment.length > 0);
  const brand = pathSegments[pathSegments.length - 3];

  const category = pathSegments[pathSegments.length - 1];

  // Get domain from current URL
  const hostname = window.location.hostname;
  const domain = hostname.split('.').slice(-2).join('.');

  const expiryDate = new Date();
  const expiryDate1 = new Date();
expiryDate1.setTime(expiryDate.getTime() + (3 * 1000));
  expiryDate.setFullYear(expiryDate.getFullYear() + 10);

  // Set cookies for both brand and category
  document.cookie = `brand=${brand};expires=${expiryDate.toUTCString()};path=/;domain=shop.${domain};SameSite=Lax`;
  document.cookie = `selected_category=${category};expires=${expiryDate1.toUTCString()};path=/;domain=shop.${domain};SameSite=Lax`;

  console.log('Setting cookies:', { brand, category });
  // Redirect to home
  setTimeout(function() {
    window.location.href = '/';
  }, 100);

  return null;
};
const App = () => {
const getBrandFromCookie = () => {
  const cookies = document.cookie.split(';');
  const brandCookie = cookies.find(cookie => cookie.trim().startsWith('brand='));
  console.log('cookie')
  console.log('cookie')
  console.log('cookie')
  console.log('cookie')
  console.log(brandCookie)
  return brandCookie ? brandCookie.split('=')[1].trim() : ''; // Default to Creoo if no cookie
};

 const brand = getBrandFromCookie()


  //const brando = 'Bep'


  // Determine the home element based on brando
  const getHomeElement = () => {
    switch(brand) {
      case 'Creoo':

        return <ShopGridStandard />;
        //return <HomeFashion />;
      case 'bep':

        return <HomeElectronicsThree />;
        //return <HomeElectronics />;
              case 'Bep':

        return <HomeElectronicsThree />;
        //return <HomeElectronics />;
      default:

        return <ShopGridStandard />;
        //return <HomeBookStoreTwo />;
    }
  };
  console.log('home element')
  console.log('brando')
  console.log(brand)


  // Get the home element
  const homeElement = getHomeElement();
  return (
      <Router>
        <ScrollToTop>
          <Suspense
            fallback={
              <div className="flone-preloader-wrapper">
                <div className="flone-preloader">
                  <span></span>
                  <span></span>
                </div>
              </div>
            }
          >
            <Routes>




<Route 
  path={process.env.PUBLIC_URL + "/Creoo"} 
  element={<CreooRedirect />} 
/>
<Route 
  path={process.env.PUBLIC_URL + "/Didex"} 
  element={<CreooRedirect />} 
/>
<Route 
  path={process.env.PUBLIC_URL + "/Bep"} 
  element={<CreooRedirect />} 
/>
<Route 
  path={process.env.PUBLIC_URL + "/tienda"} 
  element={<CreooRedirect />} 
/>
<Route path={process.env.PUBLIC_URL + "/Creoo/cat/:selected_category"} element={<CreooRedirect1 />} />
<Route path={process.env.PUBLIC_URL + "/Didex/cat/:selected_category"} element={<CreooRedirect1 />} />
<Route path={process.env.PUBLIC_URL + "/Bep/cat/:selected_category"} element={<CreooRedirect1 />} />
         
                 <Route
              path={process.env.PUBLIC_URL + "/home"}
              element={homeElement}
            />
              <Route
              path={process.env.PUBLIC_URL + "/"}
                             element={<ShopGridStandard/>}

            />

              {/* Homepages */}
           
              {/* Shop pages */}
            <Route
                path={process.env.PUBLIC_URL + "/test-home"}
                element={<HomeElectronicsThree/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-grid-standard"}
                element={<ShopGridStandard/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-grid-filter"}
                element={<ShopGridFilter/>}
              />
            
              <Route
                path={process.env.PUBLIC_URL + "/shop-list-standard"}
                element={<ShopListStandard/>}
              />
              

              {/* Shop product pages */}
              <Route
                path={process.env.PUBLIC_URL + "/product/:id"}
                element={<Product />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product-tab-left/:id"}
                element={<ProductTabLeft/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product-tab-right/:id"}
                element={<ProductTabRight/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product-sticky/:id"}
                element={<ProductSticky/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product-slider/:id"}
                element={<ProductSlider/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product-fixed-image/:id"}
                element={<ProductFixedImage/>}
              /> 

              {/* Blog pages */}
              <Route
                path={process.env.PUBLIC_URL + "/blog-standard"}
                element={<BlogStandard/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/blog-no-sidebar"}
                element={<BlogNoSidebar/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/blog-right-sidebar"}
                element={<BlogRightSidebar/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/blog-details-standard"}
                element={<BlogDetailsStandard/>}
              /> 

              {/* Other pages */}
              <Route
                path={process.env.PUBLIC_URL + "/about"}
                element={<About/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/contact"}
                element={<Contact/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/my-account"}
                element={<MyAccount/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/login-register"}
                element={<LoginRegister/>}
              />

              <Route
                path={process.env.PUBLIC_URL + "/cart"}
                element={<Cart/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/wishlist"}
                element={<Wishlist/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/compare"}
                element={<Compare/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/checkout"}
                element={<Checkout/>}
              /> 

              <Route path="*" element={<NotFound/>} />





       


        
            </Routes>
          </Suspense>
        </ScrollToTop>
      </Router>
  );
};

export default App;